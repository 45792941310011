import { connect } from 'react-redux'

import anime from 'animejs'
import Link from 'next/link'

import colors from '~/styles/colors'

import config from '~/config'
import BrandImage from '~/icons/brand.svg'


# coffeelint: disable=max_line_length
KNOBS = [
    '#knobs-bg'
    '#knob-left'
    '#knob-right'
]
flipKnobs = () ->
    anime.remove(KNOBS)
    anime.timeline().add(
        targets: '#knobs-bg'
        d: 'M18.84375,56.4899252 C17.2734375,60.1539877 13.9161168,62.3689548 9.87368421,62.3689548 C4.51797552,62.3689548 0.176315789,58.0272951 0.176315789,52.6715864 C0.176315789,47.3158777 4.51797552,42.974218 9.87368421,42.974218 C12.504976,42.974218 14.8914951,44.0222147 16.6386127,45.7235793 L23.5546875,30.8414877 C25.6484375,26.6539877 29.0915295,23.7888561 33.5881579,23.7888561 C36.2172607,23.7888561 38.6019979,24.8351099 40.3487247,26.5339727 L48.4957612,10.7456386 C50.0331467,7.4203354 53.398454,5.11315789 57.3026316,5.11315789 C62.6583403,5.11315789 67,9.45481762 67,14.8105263 C67,20.166235 62.6583403,24.5078947 57.3026316,24.5078947 C54.4547076,24.5078947 51.8935194,23.2802332 50.1194095,21.3252513 L42.5448714,37.2098456 C41.0849437,40.7175139 37.6245426,43.183593 33.5881579,43.183593 C30.8338947,43.183593 28.3478175,42.0353527 26.5825088,40.191455 L18.84375,56.4899252 Z'
    ).add(
        targets: '#knob-left'
        d: 'M9.87368421,55.357648 C11.334332,55.357648 12.5184211,54.173559 12.5184211,52.7129112 C12.5184211,51.2522634 11.334332,50.0681743 9.87368421,50.0681743 C8.41303639,50.0681743 7.22894737,51.2522634 7.22894737,52.7129112 C7.22894737,54.173559 8.41303639,55.357648 9.87368421,55.357648 Z'
        offset: 0
    ).add(
        targets: '#knob-right'
        d: 'M57.3026316,17.496588 C58.7632794,17.496588 59.9473684,16.312499 59.9473684,14.8518511 C59.9473684,13.3912033 58.7632794,12.2071143 57.3026316,12.2071143 C55.8419838,12.2071143 54.6578947,13.3912033 54.6578947,14.8518511 C54.6578947,16.312499 55.8419838,17.496588 57.3026316,17.496588 Z'
        offset: 0
    )

unflipKnobs = () ->
    anime.remove(KNOBS)
    anime.timeline().add(
        targets: '#knobs-bg'
        d: 'M18.84375,10.9921875 L26.5825088,27.2906577 C28.3478175,25.44676 30.8338947,24.2985197 33.5881579,24.2985197 C37.6245426,24.2985197 41.0849437,26.7645988 42.5448714,30.2722671 L50.1194095,46.1568614 C51.8935194,44.2018795 54.4547076,42.974218 57.3026316,42.974218 C62.6583403,42.974218 67,47.3158777 67,52.6715864 C67,58.0272951 62.6583403,62.3689548 57.3026316,62.3689548 C53.398454,62.3689548 50.0331467,60.0617773 48.4957612,56.736474 L40.3487247,40.94814 C38.6019979,42.6470028 36.2172607,43.6932566 33.5881579,43.6932566 C29.0915295,43.6932566 25.6484375,40.828125 23.5546875,36.640625 L16.6386127,21.7585333 C14.8914951,23.459898 12.504976,24.5078947 9.87368421,24.5078947 C4.51797552,24.5078947 0.176315789,20.166235 0.176315789,14.8105263 C0.176315789,9.45481762 4.51797552,5.11315789 9.87368421,5.11315789 C13.9161168,5.11315789 17.2734375,7.328125 18.84375,10.9921875 Z'
    ).add(
        targets: '#knob-left'
        d: 'M9.87368421,17.4552632 C11.334332,17.4552632 12.5184211,16.2711741 12.5184211,14.8105263 C12.5184211,13.3498785 11.334332,12.1657895 9.87368421,12.1657895 C8.41303639,12.1657895 7.22894737,13.3498785 7.22894737,14.8105263 C7.22894737,16.2711741 8.41303639,17.4552632 9.87368421,17.4552632 Z'
        offset: 0
    ).add(
        targets: '#knob-right'
        d: 'M57.3026316,55.3163232 C58.7632794,55.3163232 59.9473684,54.1322342 59.9473684,52.6715864 C59.9473684,51.2109385 58.7632794,50.0268495 57.3026316,50.0268495 C55.8419838,50.0268495 54.6578947,51.2109385 54.6578947,52.6715864 C54.6578947,54.1322342 55.8419838,55.3163232 57.3026316,55.3163232 Z'
        offset: 0
    )

Brand = ({
    className, id, style, children, color,
    hoverColor, brandColors, batchActions, props...
}) ->
    <Link prefetch href='/'>
        <a>
            <BrandImage
                className="
                    d-none d-md-block brand-image
                    #{ className ? '' }"
                id={ id ? '' }
                style={ style }
                height={ 30 }
                onMouseEnter={ flipKnobs }
                onMouseLeave={ unflipKnobs }
                { props... } />
            <style jsx>{"""#{} // stylus
                a
                    z-index 1
                    :global(.brand-image)
                        color #{ color ? brandColors.color ? colors.WHITE }
                        ease-out 0.3s 'color' 'fill'

                        &:hover
                        &:focus
                            color #{ hoverColor ? brandColors.hoverColor ? colors.YELLOW }
            """}</style>
        </a>
    </Link>

mapStateToProps = ({ ui }) ->
    brandColors: ui.brandColors

mapDispatchToProps = (dispatch) ->
    batchActions: (actions) -> dispatch(actions)

export default connect(mapStateToProps, mapDispatchToProps)(Brand)
